<template>
  <div>
    <b-card-group deck>
      <b-card
        header="Основная информация"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-form-group
          id="input-group-1"
          label="Название на русском"
          label-for="title_ru"
        >
          <b-form-input
            id="title_ru"
            placeholder="Введите название на русском"
            v-model="clinic.title.ru"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Название на узбекском"
          label-for="title_uz"
        >
          <b-form-input
            id="title_uz"
            type="text"
            v-model="clinic.title.uz"
            placeholder="Введите название на узбекском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Название на английском"
          label-for="title_uz"
        >
          <b-form-input
            id="title_uz"
            type="text"
            v-model="clinic.title.en"
            placeholder="Введите название на английском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Адресс на русском"
          label-for="address_ru"
        >
          <b-form-input
            id="address_ru"
            type="text"
            v-model="clinic.address.ru"
            placeholder="Введите адресс на русском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Адресс на узбекском"
          label-for="address_uz"
        >
          <b-form-input
            id="address_uz"
            type="text"
            v-model="clinic.address.uz"
            placeholder="Введите адресс на узбекском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Адресс на английском"
          label-for="address_uz"
        >
          <b-form-input
            id="address_uz"
            type="text"
            v-model="clinic.address.en"
            placeholder="Введите адресс на английском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание на русском"
          label-for="description_ru"
        >
          <b-form-textarea
            id="description_ru"
            v-model="clinic.description.ru"
            placeholder="Введите описание на русском">
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание на узбекском"
          label-for="description_uz"
        >
          <b-form-textarea
            id="description_uz"
            v-model="clinic.description.uz"
            placeholder="Введите описание на узбекском">
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание на английском"
          label-for="description_uz"
        >
          <b-form-textarea
            id="description_uz"
            v-model="clinic.description.en"
            placeholder="Введите описание на английском">
          </b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Специализация на русском"
          label-for="specialization_ru"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="clinic.specialization.ru"
            placeholder="Введите специализацию на русском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Специализация на узбекском"
          label-for="specialization_uz"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="clinic.specialization.uz"
            placeholder="Введите специализацию на узбекском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Специализация на английском"
          label-for="specialization_uz"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="clinic.specialization.en"
            placeholder="Введите специализацию на английском"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="ОКПО"
          label-for="specialization_uz"
        >
          <b-form-input
            id="input-1"
            type="text"
            v-model="clinic.GCEO"
            placeholder="Введите ОКПО"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Специализация"
          label-for="title-uz"
        >
          <el-select
            size="large"
            v-model="clinic.clinic_specialty_ids"
            multiple
            style="width: 100%"
            filterable
            remote
            reserve-keyword
            placeholder="Выберите специализацию"
            :remote-method="listClinicSpecialties"
            :loading="clinicSpecialtySelect.loading">
            <el-option
              v-for="item in clinicSpecialtySelect.options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Связанные клиники"
          label-for="title-uz"
        >
          <el-select
            size="large"
            v-model="clinic.clinics_id"
            style="width: 100%"
            filterable
            remote
            multiple
            reserve-keyword
            placeholder="Выберите клиники"
            :remote-method="clinics"
            :loading="clinicsSelect.loading">
            <el-option
              v-for="item in clinicsSelect.options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Логотип"
          label-for="input-1"
        >
          <div v-if="clinic.logo !== undefined">
            <b-img v-model="clinic.logo.link" :src="clinic.logo.link"></b-img>
          </div>

          <div class="upload-example">
            <cropper
              ref="cropper"
              class="upload-example-cropper"
              :src="image.src"
              :stencil-props="{
		            aspectRatio: 1
	            }"
            />
            <div class="button-wrapper mt-2">
              <b-form-file variant="primary" ref="file" @change="loadImage($event)" accept="image/*"></b-form-file>
            </div>
          </div>
        </b-form-group>
        <b-form-checkbox
          v-model="clinic.is_favorite"
          id="is_favorite"
          name="is_favorite"
          value="true"
          unchecked-value="false"
        >
          Выводить в топ
        </b-form-checkbox>
      </b-card>

      <b-card
        header="Контакты"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white">
        <b-form-group
          id="input-group-1"
          label="Телефон"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            type="text"
            v-model="clinic.phones"
            placeholder="Введите телефон"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Адрес сайта"
          label-for="site_url"
        >
          <b-form-input
            id="site_url"
            type="text"
            v-model="clinic.site_url"
            placeholder="Введите адрес сайта"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Facebook"
          label-for="facebook"
        >
          <b-form-input
            id="facebook"
            type="text"
            v-model="clinic.facebook"
            placeholder="Введите Facebook"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Instagram"
          label-for="instagram"
        >
          <b-form-input
            id="instagram"
            type="text"
            v-model="clinic.instagram"
            placeholder="Введите Instagram"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Telegram"
          label-for="telegram"
        >
          <b-form-input
            id="telegram"
            type="text"
            v-model="clinic.telegram"
            placeholder="Введите Telegram"
          ></b-form-input>
        </b-form-group>
      </b-card>
    </b-card-group>
    <b-card-group deck style="margin-top: 30px;">
      <b-card
        header="Информация о местоположении"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white"
      >
        <b-form-group
          id="input-group-1"
          label="Город"
          label-for="city"
        >
          <el-select
            size="large"
            v-model="clinic.city.id"
            style="width: 100%"
            filterable
            remote
            reserve-keyword
            placeholder="Выберите город"
            :remote-method="cities"
            :loading="citiesSelect.loading">
            <el-option
              v-for="item in citiesSelect.options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Район"
          label-for="district"
        >
          <el-select
            size="large"
            v-model="clinic.district.id"
            style="width: 100%"
            filterable
            remote
            reserve-keyword
            placeholder="Выберите город"
            :remote-method="districts"
            :loading="districtsSelect.loading">
            <el-option
              v-for="item in districtsSelect.options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание адреса (ориентиры) на узбекском"
          label-for="address_description_ru"
        >
          <b-form-textarea
            id="address_description_ru"
            v-model="clinic.address_description.ru"
            placeholder="Введите описание адреса на русском"
            size="sm"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание адреса (ориентиры) на узбекском"
          label-for="address_description_uz"
        >
          <b-form-textarea
            id="address_description_uz"
            v-model="clinic.address_description.uz"
            placeholder="Введите описание адреса на узбекском"
            size="sm"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Описание адреса (ориентиры) на английском"
          label-for="address_description_uz"
        >
          <b-form-textarea
            id="address_description_uz"
            v-model="clinic.address_description.en"
            placeholder="Введите описание адреса на английском"
            size="sm"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Широта"
          label-for="latitude"
        >
          <b-form-input
            id="latitude"
            type="text"
            v-model="clinic.latitude"
            placeholder="Введите широту"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Долгота"
          label-for="longitude"
        >
          <b-form-input
            id="longitude"
            type="text"
            v-model="clinic.longitude"
            placeholder="Введите долготу"
          ></b-form-input>
        </b-form-group>
      </b-card>

      <b-card
        header="Рабочее время"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white">
        <b-form-group
          id="input-group-1"
          label="Мертвая зона"
          label-for="price"
        >
          <b-form-input
            id="price"
            v-model="clinic.death_zone"
            placeholder="Введите мертвую зону"
            type="number"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Понедельник от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.monday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.monday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Вторник от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.tuesday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.tuesday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Среда от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.wednesday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.wednesday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Четверг от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.thursday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.thursday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Пятница от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.friday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.friday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Суббота от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.saturday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.saturday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Воскресенье от - до"
        >
          <div style="float: left">
            <el-time-select
              v-model="clinic.working_time.sunday_start"
              placeholder="От"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
          <div style="float: left; margin-left: 20px;">
            <el-time-select
              v-model="clinic.working_time.sunday_end"
              placeholder="До"
              :picker-options="{
                start: '00:00',
                step: '00:01',
                end: '24:00'
              }">
            </el-time-select>
          </div>
        </b-form-group>

      </b-card>
    </b-card-group>
    <b-card-group style="margin-top: 30px;">
      <b-card
        header="Функционал"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white">
        <b-form-group label="Тип модалки записи">
          <el-select size="large" v-model="clinic.appointmentModalFlow" style="width: 100%">
            <el-option
              v-for="item in appointmentModalFlows" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </b-form-group>

        <b-form-group label="Тип интерфейса">
          <el-select size="large" v-model="clinic.interface" style="width: 100%">
            <el-option
              v-for="item in interfaces" :key="item.key" :label="item.value" :value="item.key">
            </el-option>
          </el-select>
        </b-form-group>

        <b-form-group label="Будут ли счета у курса лечений">
          <div style="float: left">
            <b-form-checkbox
              v-model="clinic.treatmentAppointmentHasCustomPrice"
              id="treatmentAppointmentHasCustomPrice"
              name="treatmentAppointmentHasCustomPrice"
              :value=true
              :unchecked-value=false
            >
              Будут ли цена у персональных назначений в курсе лечений
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group label="ID в MEDHUB">
          <b-form-input placeholder="ID в MEDHUB" v-model="clinic.med_hub_id" type="text"></b-form-input>
        </b-form-group>

        <b-form-group label="ID в ITMed">
          <b-form-input placeholder="ID в ITMed" v-model="clinic.it_med_id" type="text"></b-form-input>
        </b-form-group>
      </b-card>
    </b-card-group>
    <b-card-group style="margin-top: 30px;">
      <b-card
        header="Дополнительная информация"
        border-variant="primary"
        header-bg-variant="primary"
        header-text-variant="white">
        <b-form-group
          id="input-group-1"
        >
          <div style="float: left">
            <b-form-checkbox
              v-model="clinic.has_hospital"
              id="has_hospital"
              name="has_hospital"
              value="true"
              unchecked-value="false"
            >
              Есть госпиталь
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-model="clinic.has_ambulance"
              id="has_ambulance"
              name="has_ambulance"
              value="true"
              unchecked-value="false"
            >
              Есть скорая помощь
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-model="clinic.is_working_all_day"
              id="is_working_all_day"
              name="is_working_all_day"
              value="true"
              unchecked-value="false"
            >
              Круглосуточная работа
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-model="clinic.has_pharmacy"
              id="has_pharmacy"
              name="has_pharmacy"
              value="true"
              unchecked-value="false"
            >
              Есть аптека
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.use_admin"
              id="use_admin"
              name="use_admin"
              value="true"
              unchecked-value="false"
            >
              Админка подключена
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.is_gov"
              :value=true
              :unchecked-value=false
            >
              Гос. учреждение
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.redirect_to_dmed"
              :value=true
              :unchecked-value=false
            >
              Перенести на DMED
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.my_id"
              :value=true
              :unchecked-value=false
            >
              MyId
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.isolate_patient_data"
              :value=true
              :unchecked-value=false
            >
              Изолировать данные пациента
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.create_hospital"
              :value=false
              :unchecked-value=true
            >
              Приемный покой
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.service_category"
              :value=true
              :unchecked-value=false
            >
              Категория услуг в счетах
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.negative_deposit"
              :value=true
              :unchecked-value=false
            >
              Отрицательный депозит
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.cash_register"
              :value=true
              :unchecked-value=false
            >
              Касса
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.order_transfer"
              :value=true
              :unchecked-value=false
            >
              ЦКДЛ
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.new_lis"
              :value=true
              :unchecked-value=false
            >
              Переключить на новый лис
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.send_analysis_to_inspection"
              :value=true
              :unchecked-value=false
            >
              Отправлять анализы на проверку
            </b-form-checkbox>
          </div>
          <div style="float: left; margin-left: 40px">
            <b-form-checkbox
              v-if="admin"
              v-model="clinic.is_republican"
              :value=true
              :unchecked-value=false
            >
              Республиканский центр
            </b-form-checkbox>
          </div>
        </b-form-group>
        <div v-if="ambulance">
          <b-form-group
            id="input-group-1"
            label="Цена вызова скорой помощи"
            label-for="emergency_call_price"
          >
            <b-form-input
              id="emergency_call_price"
              type="number"
              v-model="clinic.emergency_call_price"
              placeholder="0"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-1"
            label="Телефон скорой помощи"
            label-for="emergency_phone"
          >
            <b-form-input
              id="emergency_phone"
              type="text"
              v-model="clinic.emergency_phone"
              placeholder="+XXXXXXXXXXXX"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>

import {ApiClient} from '../../../../services/backend-client';
import {Card, Tabs, TabPane, Select, Option} from 'element-ui';
import {TimeSelect} from 'element-ui'
import {Cropper} from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import {list} from "../../../../services/clinic-specialty.service";
import {list as listClinic} from "../../../../services/clinic.service"
import {list as listCities} from "../../../../services/city.service"
import {list as listDistricts} from "../../../../services/district.service"

const API_URL = '/admin/api/';


function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

export default {
  name: "CreateClinic",

  components: {
    [Card.name]: Card,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    Cropper,
  },

  data() {
    return {
      clinic: {
        title: {ru: null, uz: null, en: null},
        address: {ru: null, uz: null, en: null},
        description: {ru: null, uz: null, en: null},
        specialization: {ru: null, uz: null, en: null},
        is_favorite: false,
        phones: null,
        site_url: null,
        facebook: null,
        instagram: null,
        telegram: null,
        city: {
          id: null,
        },
        district: {
          id: null,
        },
        address_description: {ru: null, uz: null, en: null},
        latitude: null,
        longitude: null,
        working_time: {
          monday_start: null,
          monday_end: null,
          tuesday_start: null,
          tuesday_end: null,
          wednesday_start: null,
          wednesday_end: null,
          thursday_start: null,
          thursday_end: null,
          friday_start: null,
          friday_end: null,
          saturday_start: null,
          saturday_end: null,
          sunday_start: null,
          sunday_end: null,
        },
        has_hospital: false,
        has_ambulance: false,
        is_working_all_day: false,
        has_pharmacy: false,
        use_admin: false,
        emergency_call_price: null,
        emergency_phone: null,
        death_zone: null,
        clinic_specialty_ids: null,
        is_gov: false,
        clinics_id: [],
        appointmentModalFlow: null,
        interface: null,
        med_hub_id: null,
        treatmentAppointmentHasCustomPrice: null,
        redirect_to_dmed: null,
        cash_register: null,
        my_id: null,
        create_hospital: null,
        GCEO: null,
        it_med_id: null,
        service_category: null,
        negative_deposit: null,
        isolate_patient_data: null,
        order_transfer: false,
        new_lis: false,
        send_analysis_to_inspection: false,
        is_republican: false,
      },
      modalOk: false,
      image: {
        src: null,
        type: null
      },

      clinicSpecialtySelect: {
        loading: false,
        options: [],
      },

      clinicsSelect: {
        loading: false,
        options: [],
      },

      districtsSelect: {
        loading: false,
        options: [],
      },

      citiesSelect: {
        loading: false,
        options: [],
      },

      appointmentModalFlows: [
        {key: 'g2g', value: 'G2G'},
        {key: 'b2g', value: 'B2G'}
      ],
      interfaces: [
        {key: 'g2g', value: 'G2G'},
        {key: 'b2g', value: 'B2G'}
      ]
    };
  },
  methods: {
    clinics(query) {
      this.clinicsSelect.loading = true;
      listClinic(query, 1).then(response => {
        this.clinicsSelect.options = response.data.data;
        this.clinicsSelect.loading = false;
      });
    },

    cities(query) {
      this.citiesSelect.loading = true
      listCities(query, 1).then(response => {
        this.citiesSelect.options = response.data.data;
        this.citiesSelect.loading = false;
      });
    },

    districts(query) {
      this.districtsSelect.loading = true
      listDistricts(query, this.clinic.city.id, 1).then(response => {
        this.districtsSelect.options = response.data.data;
        this.districtsSelect.loading = false;
      });
    },

    createClinic() {
      ApiClient.post(API_URL + 'clinics/create', this.clinic)
        .then(response => {
          const {canvas} = this.$refs.cropper.getResult();
          if (canvas) {
            const form = new FormData();
            canvas.toBlob(blob => {
              form.append('file', blob);
              ApiClient.post(API_URL + 'clinics/' + response.data.data.id + '/logo', form)
            }, 'image/jpeg');
          }

          this.image.src = null;
          this.image.type = null;
        });
    },
    crop() {
      const {canvas} = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null
      }
    },
    loadImage(event) {
      this.clinic.logo = undefined;
      // Reference to the DOM input element
      const {files} = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },

    listClinicSpecialties(query) {
      this.clinicSpecialtySelect.loading = true;
      list(query, 1).then(response => {
        this.clinicSpecialtySelect.options = response.data.data;
        this.clinicSpecialtySelect.loading = false;
      });
    }
  },
  destroyed() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src)
    }
  },

  created() {
    this.cities('');
    this.districts('');
    this.listClinicSpecialties('');
    this.clinics('');
  },
  computed: {
    admin() {
      if (this.$store.getters["auth/user"]) {
        return this.$store.getters["auth/user"].role === 'super-admin';
      }
    },
    ambulance() {
      if (this.clinic.has_ambulance) {
        return true;
      } else {
        return false;
      }
    }
  },
}
</script>

<style scoped>

</style>
